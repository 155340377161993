<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        
        <template v-if="userType === 'PASSWORD'">
            <van-field class="inputMessage" left-icon="shield-o" v-model.trim="oldpwd" type="password" label="当前密码" clearable placeholder="请输入当前密码" />
            <van-field class="inputMessage" left-icon="shield-o" v-model.trim="newpwd" type="password" label="新密码" clearable placeholder="请输入新密码" />
            <van-field class="inputMessage" left-icon="shield-o" v-model.trim="newpwd2" type="password" label="再次输入密码" clearable placeholder="请再次输入新密码" />
        </template>
        <template v-if="userType === 'SMS'" style="border-radius: 10px;margin-bottom: 10px;">
            <van-field class="inputMessage" left-icon="phone-o" v-model.trim="username" type="tel" clearable label="手机号" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号" />
            <van-field class="inputMessage" left-icon="shield-o" v-model.trim="verCode" center clearable label="短信验证码" placeholder="请输入短信验证码">
            <template #button>
                <van-button size="small" type="primary" @click="sendCode">
                <span v-if="!sendDisabled">发送验证码</span>
                <span v-else class="smsVerifyCode">{{time+'秒后重新获取'}}</span>
                </van-button>
            </template>
            </van-field>
            <van-field class="inputMessage" left-icon="shield-o" v-model.trim="newpwd" type="password" label="新密码" clearable placeholder="请输入新密码" />
            <van-field class="inputMessage" left-icon="shield-o" v-model.trim="newpwd2" type="password" label="再次输入密码" clearable placeholder="请再次输入新密码" />
        </template>

        <van-field label="修改密码类型" left-icon="exchange" style="border-radius: 10px;margin-bottom: 10px;">
            <template #input>
            <van-radio-group v-model="userType" direction="horizontal">
                <van-radio name="PASSWORD">密码方式修改</van-radio>
                <br>
                 <van-radio name="SMS" style="margin-top: 10px;">短信方式修改</van-radio>
            </van-radio-group>

            </template>
        </van-field>
        <!-- <div class="content">
            <div>
                <div class="item">
                    <span>旧的登录密码</span>
                    <input type="password" v-model="oldpwd">
                </div>
            </div>
            <div>
                <div class="item">
                    <span>新密码</span>
                    <input type="password" v-model="newpwd">
                </div>
            </div>
            <div>
                <div class="item">
                    <span>再次输入新密码</span>
                    <input type="password" v-model="newpwd2">
                </div>
            </div>
        </div> -->
        <div style="margin: 36px;">
            <van-button round block type="info" @click="onSubmit(userType)">提交</van-button>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import { Toast } from 'vant';
import { mapState } from 'vuex';
import validator from '@/assets/js/util/validator';

export default {
    data(){
        return{
            title:this.$route.meta.title,
            username: '',
            oldpwd: '',
            newpwd: '',
            newpwd2: '',
            verCode: '',
            userType: 'PASSWORD',
            sendDisabled: false,
            time: 60,
        }
    },
    components:{
        vHeader
    },
    computed: {
            ...mapState({
            loginInfo: state => state.common.loginInfo,
            userInfo: state => state.common.userInfo
        })
    },
    methods: {
        //这里的接口还需要改
        async onSubmit(type){
            let newpwd = this.newpwd
            let newpwd2 = this.newpwd2
            if(newpwd !== newpwd2&&(newpwd!==''||newpwd2!=='')){
                Toast({
                message: '新密码不一致或新密码不能为空',
                position: 'middle',
                duration: 3000
                });
            }else{
                if(type==='PASSWORD'){
                    let oldpwd = this.oldpwd
                    if(newpwd===''||newpwd2===''||oldpwd===''){
                        Toast({
                        message: '请填写完整后再提交',
                        position: 'middle',
                        });
                    }else{
                        if (this.loginInfo.access_token && this.loginInfo.userId) {
                            const res = await this.$axios({
                                method: "put",
                                url: this.$API['API_USER_EDIT_PASSWORD'] + '?newPassword=' + newpwd + '&oldPassword='+oldpwd,
                                headers: { access_token: this.loginInfo.access_token }
                            })
                            if(res.data.statusCode == 305){
                                Toast({
                                message: '旧密码输入错误',
                                position: 'middle',
                                });
                            }
                            if(res.data.statusCode == 200){
                                Toast({
                                message: '修改成功',
                                position: 'middle',
                                });
                                const res = await this.$HTTP.get(this, this.$API['API_USER_LOGOUT'],{}, { headers: { access_token: this.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    this.$store.commit('common/updateState', [{ name: ['loginInfo'], value: {} }])
                                    localStorage.clear()
                                    sessionStorage.clear()
                                    this.goPage('login')
                                }
                            }
                        }
                    }
                }
                if(type==='SMS'){
                  let verCode = this.verCode
                    let username=this.username
                    if(newpwd===''||newpwd2===''||verCode===''||username===''){
                        Toast({
                        message: '请填写完整后再提交',
                        position: 'middle',
                        });
                    }else{
                        if (this.loginInfo.access_token && this.loginInfo.userId) {
                          const res = await this.$axios({
                                method: "put",
                                url: this.$API['API_USER_CHG_PASSWORD'],
                                data: {'changeType':1,'verifyCode':this.verCode,'phoneNumber':this.username,'oldPwd':this.oldpwd,'newPwd':this.newpwd},
                                headers: { access_token: this.loginInfo.access_token }
                            })
                          console.log("数据",res);
                          if(res.data.statusCode == 307){
                            Toast({
                              message: res.data.responseDescription,
                              position: 'middle',
                            });
                          }
                            if(res.data.statusCode == 309){
                                Toast({
                                message: '验证码输入错误',
                                position: 'middle',
                                });
                            }
                            if(res.data.statusCode == 200){
                                Toast({
                                message: '修改成功',
                                position: 'middle',
                                });
                                const res = await this.$HTTP.get(this, this.$API['API_USER_LOGOUT'],{}, { headers: { access_token: this.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    this.$store.commit('common/updateState', [{ name: ['loginInfo'], value: {} }])
                                    localStorage.clear()
                                    sessionStorage.clear()
                                    this.goPage('login')
                                }
                            }
                        }
                    }

                }

            }
           
           
        },
        //获取验证码
        async sendCode() {
        if (this.sendDisabled) {
            return;
        }
        if (!validator.isMobile(this.username, "手机号码格式有误")) {
        return
        }
        const res = await this.$HTTP.post(this, 'API_USERCENTER_LOGIN_SIGNUP_CODE', {
            phoneNumber: this.username,
            smsType: '2' // smsType 发短信类型 1注册 2登录
        }, {}, true)
        if (res.statusCode === 200) {
            // this.smsVerifyCode = res.responseDescription
            this.$toast(`验证码已发送至您的手机${this.username}`);
            this.sendDisabled = true;
            window.setTimeLoginObj = window.setInterval(() => {
            if ((this.time--) <= 0) {
                this.time = 60;
                this.sendDisabled = false;
                clearInterval(window.setTimeLoginObj);
            }
            }, 1000);
        } else {
            this.$toast(res.responseDescription || '请求失败');
        }
        }
    },
}
</script>
<style lang="less">
    .inputMessage{
        border-radius: 10px;
        margin-bottom: 10px;
        height: 50px;
        line-height: 30px;
    }
    .smsVerifyCode {
      width: 7.5em;
      display: block;
    }
    // .container{
    //     width: 100%;
    //     height: 100%;
    //     .content{
    //         width: 100%;
    //         height: 120px;
    //         border-top: 1px solid #ccc;
    //         div{
    //             width: 100%;
    //             height: 40px;
    //             background-color: #fff;
    //             border-bottom: 1px solid #ccc;
    //             .item{
    //                 width: 92%;
    //                 margin: 0 auto;
    //                 height: 40px;
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: space-between;
    //                 span{
    //                     font-size: 14px;
    //                 }
    //                 input{
    //                     width: 180px;
    //                     height: 18px;
    //                     border: none;
    //                     border: 1px solid #ddd;
    //                     border-radius: 4px;
    //                 }
    //             }
    //         }
    //     }
    // }
</style>